import './App.css';
import CardMain from './components/CardMain';
import { AppProvider } from './context/AppContext';
import { LanguajeProvider } from './context/LanguajeContext';

function App() {
  return (
    <AppProvider>
      <LanguajeProvider>
      <div className="main">
        <CardMain/>
      </div>
      </LanguajeProvider>
    </AppProvider>
  );
}

export default App;
