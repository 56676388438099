export const infoCartTerminate = {
    es: {
        MessageCard: {
            person: {
                firstParagraph: 'Ahora que conoces cuál es tu impacto ambiental al planeta puedes hacer algo al respecto. Te invitamos a compensar tu huella de carbono apoyando el financiamiento climático de familias protectoras de los bosques.',
                secondParagraph1: 'Conoce los proyectos de conservación ambiental que tenemos disponibles para compensar un total de ',
                secondParagraph2: 'bonos/créditos de carbono.',
            },
            company: {
                firstParagraph1: 'Este es un resultado aproximado de tus emisiones de CO2, sin embargo, no es el definitivo. Recuerda que según la Ley 2169 de Acción Climática del 21 de diciembre del 2021, (artículo 16), todas las personas jurídicas, públicas, privadas o mixtas, considerando el nivel de emisiones de GEl y el tamaño de las empresas, deberán reportar de forma obligatoria sus emisiones directas e indirectas de GEl.',
                firstParagraph2: 'Te invitamos a ponerte en contacto con nosotros para acompañarte en el trabajo de medición de la huella de carbono basado en metodologías GHG Protocol e ISO-14064. Te ayudamos a calcular tus emisiones, a generar políticas de mitigación y a desarrollar un plan de gestión.',
                secondParagraph1: 'Quiero contactarme:',
            },
            firstButton: 'Volver a calcular',
            secondButton: 'Quiero conocer proyectos y compensar',

        },
        CardTerminate: {
            person: {
                title: '¡Has calculado tu huella de carbono!',
                Paragraph: 'Durante un año has emitido:',
            },
            company: {
                title: '¡Has calculado un aproximado de la huella de carbono de tu empresa!',
                Paragraph: 'Durante un año tu empresa ha emitido:',
            },
            h3: 'Año',
        },
    },
    en: {
        MessageCard: {
            person: {
                firstParagraph: 'Now that you know what your environmental impact on the planet is, you can do something about it. We invite you to offset your carbon footprint by supporting climate financing for families who protect the forests.',
                secondParagraph1: 'Find out about the environmental conservation projects that we have available to compensate a total of ',
                secondParagraph2: 'bonuses/credits of carbon.',
            },
            company: {
                firstParagraph1: 'This is an approximate result of your CO2 emissions, however, it is not the final one. Remember that according to Law 2169 on Climate Action of December 21, 2021, (article 16), all legal persons, public, private or mixed, considering the level of GHG emissions and the size of the companies, must report obligatory its direct and indirect GHG emissions.',
                firstParagraph2: 'We invite you to contact us to accompany you in the work of measuring the carbon footprint based on GHG Protocol and ISO-14064 methodologies. We help you calculate your emissions, generate mitigation policies and develop a management plan.',
                secondParagraph1: 'I want to contact me:',
            },
            firstButton: 'recalculate',
            secondButton: 'I want to know projects and compensate',

        },
        CardTerminate: {
            person: {
                title: 'You have calculated your carbon footprint!',
                Paragraph: 'During a year you have issued:',
            },
            company: {
                title: 'You have calculated an estimate of the carbon footprint of your company!',
                Paragraph: 'During one year your company has issued:',
            },
            h3: 'Year',
        },
    },
    pt: {
        MessageCard: {
            person: {
                firstParagraph: 'Agora que você sabe qual é o seu impacto ambiental no planeta, pode fazer algo a respeito. Convidamos você a compensar sua pegada de carbono apoiando o financiamento climático para famílias que protegem as florestas.',
                secondParagraph1: 'Conheça os projetos de conservação ambiental que temos disponíveis para compensar um total de ',
                secondParagraph2: 'bônus/créditos de carbono.',
            },
            company: {
                firstParagraph1: 'Este é um resultado aproximado de suas emissões de CO2, porém não é o resultado final. Lembre-se que de acordo com a Lei 2.169 sobre Ação Climática de 21 de dezembro de 2021, (artigo 16), todas as pessoas jurídicas, públicas, privadas ou mistas, considerando o nível de emissões de GEE e o porte das empresas, devem reportar obrigatoriamente seus diretos e indiretos emissões de GEE.',
                firstParagraph2: 'Convidamos você a entrar em contato conosco para acompanhá-lo no trabalho de medição da pegada de carbono com base nas metodologias GHG Protocol e ISO-14064. Ajudamos você a calcular suas emissões, gerar políticas de mitigação e desenvolver um plano de gestão.',
                secondParagraph1: 'quero entrar em contato comigo:',
            },
            firstButton: 'recalcular',
            secondButton: 'Eu quero compensar',

        },
        CardTerminate: {
            person: {
                title: 'Você calculou sua pegada de carbono!',
                Paragraph: 'Durante um ano você emitiu:',
            },
            company: {
                title: 'Você calculou uma estimativa da pegada de carbono da sua empresa!',
                Paragraph: 'Durante um ano sua empresa emitiu:',
            },
            h3: 'Ano',
        },
    }
};
    