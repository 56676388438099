export const infoDescriptionQuestions = {
    es: { 
        Questions: [
       
            {
                id:1,
                type: 1,
                category: 1,
                question: '¿Cuántas personas viven contigo?',
                description: 'Antes de comenzar, queremos saber con cuántas personas vives. Esto nos ayudará a saber tu huella de CO2, con datos que tienes a la mano.',
                label: 'Personas'
            },
            {   
                id:2,
                type: 1,
                category: 1,
                question: '¿Cuál es tu consumo de gas al mes?',
                description: 'Ingresa el valor en pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:3,
                type: 1,
                category: 1,
                question: '¿Cuál es tu consumo de energía al mes?',
                description: 'Ingresa el valor en pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:4,
                type: 1,
                category: 1,
                question: '¿Con qué frecuencia comes carne por semana?',
                description: 'Ingresa el número de veces.',
                label: ''
            },
            {
                id:5,
                type: 1,
                category: 2,
                question: '¿Qué medio de transporte utilizas frecuentemente?',
                description: 'Ingresa el número de Km recorridos por semana.',
                label: 'Km'
            },
            {
                id:6,
                type: 1,
                category: 2,
                question: '¿Cuántos vuelos tomaste el año pasado de menos de 3 horas?',
                description: 'Ingresa el número de vuelos.',
                label: 'Vuelos'
            },
            {
                id:7,
                type: 1,
                category: 2,
                question: '¿Cuántos vuelos tomaste el año pasado de más de 3 horas?',
                description: 'Ingresa el número de vuelos.',
                label: 'Vuelos'
            },
        
            {
                id:1,
                type: 2,
                category: 1,
                question: '¿Cuántos empleados tiene tu empresa?',
                description: 'Antes de comenzar, queremos saber con cuántas personas trabajan contigo. Esto nos ayudará a saber tu huella de CO2, con datos que tienes a la mano.',
                label: 'Personas'
            },
            {
                id:8,
                type: 2,
                category: 1,
                question: '¿Cuál es el consumo de gas de tu empresa al mes?',
                description: 'Ingresa el valor en pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:9,
                type: 2,
                category: 1,
                question: '¿Cuál es el consumo de energía de tu empresa al mes?',
                description: 'Ingresa el valor en pesos colombianos (COP)',
                label: ' COP'
            },
            {
                id:10,
                type: 2,
                category: 2,
                question: '¿Cuántos vuelos tomaron tus empleados el año pasado de menos de 3 horas?',
                description: 'Ingresa el número de vuelos.',
                label: 'Vuelos'
            },
            {
                id:11,
                type: 2,
                category: 2,
                question: '¿Cuántos vuelos tomaron tus empleados el año pasado de más de 3 horas?',
                description: 'Ingresa el número de vuelos.',
                label: 'Vuelos'
            },
            {
                id:12,
                type: 2,
                category: 3,
                question: '¿Cuántos galones de estos combustibles utiliza tu empresa al mes?',
                description: 'Ingresa la cantidad de galones por cada combustible.',
                label: 'galones'
            },
            {
                id:13,
                type: 2,
                category: 3,
                question: '¿Qué tipo de refrigerantes usa tu empresa? Indica sus cantidades estimadas al mes',
                description: 'Selecciona los tipos de refrigerantes que usa tu empresa',
                label: ''
            }
        ]
    },
    en: {
        Questions: [
       
            {
                id:1,
                type: 1,
                category: 1,
                question: 'How many people live with you?',
                description: 'Before we start, we want to know how many people you live with. This will help us to know your CO2 footprint, with data that you have at hand.',
                label: 'People'
            },
            {   
                id:2,
                type: 1,
                category: 1,
                question: 'What is your gas consumption per month?',
                description: 'Enter the value in USD',
                label: 'USD'
            },
            {
                id:3,
                type: 1,
                category: 1,
                question: 'What is your energy consumption per month?',
                description: 'Enter the value in USD',
                label: 'USD'
            },
            {
                id:4,
                type: 1,
                category: 1,
                question: 'How often do you eat meat per week?',
                description: 'Enter the number of times.',
                label: ''
            },
            {
                id:5,
                type: 1,
                category: 2,
                question: 'What means of transport do you frequently use?',
                description: 'Enter the number of km traveled per week.',
                label: 'Km'
            },
            {
                id:6,
                type: 1,
                category: 2,
                question: 'How many flights did you take last year of less than 3 hours?',
                description: 'Enter the number of flights.',
                label: 'flights'
            },
            {
                id:7,
                type: 1,
                category: 2,
                question: 'How many flights did you take last year of more than 3 hours?',
                description: 'Enter the number of flights.',
                label: 'flights'
            },
        
            {
                id:1,
                type: 2,
                category: 1,
                question: 'How many employees does your company have?',
                description: 'Before we start, we want to know how many people you work with. This will help us to know your CO2 footprint, with data that you have at hand.',
                label: 'People'
            },
            {
                id:8,
                type: 2,
                category: 1,
                question: 'What is the gas consumption of your company per month?',
                description: 'Enter the value in Colombian pesos (COP)',
                label: 'COP'
            },
            {
                id:9,
                type: 2,
                category: 1,
                question: 'What is the energy consumption of your company per month?',
                description: 'Enter the value in Colombian pesos (COP)',
                label: ' COP'
            },
            {
                id:10,
                type: 2,
                category: 2,
                question: 'How many flights did your employees take last year under 3 hours?',
                description: 'Enter the number of flights.',
                label: 'flights'
            },
            {
                id:11,
                type: 2,
                category: 2,
                question: 'How many flights did your employees take last year of more than 3 hours?',
                description: 'Enter the number of flights.',
                label: 'flights'
            },
            {
                id:12,
                type: 2,
                category: 3,
                question: 'How many gallons of these fuels does your company use per month?',
                description: 'Enter the number of gallons for each fuel.',
                label: 'gallons'
            },
            {
                id:13,
                type: 2,
                category: 3,
                question: 'What kind of refrigerants does your company use? Indicates your estimated amounts per month',
                description: 'Select the types of refrigerants your company uses',
                label: ''
            }
        ]
    },
    pt: {
        Questions: [
       
            {
                id:1,
                type: 1,
                category: 1,
                question: 'Quantas pessoas moram com você?',
                description: 'Antes de começar, queremos saber com quantas pessoas você mora. Isso nos ajudará a conhecer sua pegada de CO2, com os dados que você tem em mãos.',
                label: 'People'
            },
            {   
                id:2,
                type: 1,
                category: 1,
                question: 'Qual é o seu consumo de gás por mês?',
                description: 'Digite o valor em pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:3,
                type: 1,
                category: 1,
                question: 'Qual é o seu consumo de energia por mês?',
                description: 'Digite o valor em pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:4,
                type: 1,
                category: 1,
                question: 'Quantas vezes você come carne por semana?',
                description: 'Digite o número de vezes.',
                label: ''
            },
            {
                id:5,
                type: 1,
                category: 2,
                question: 'Que meio de transporte utiliza frequentemente?',
                description: 'Insira o número de km percorridos por semana.',
                label: 'Km'
            },
            {
                id:6,
                type: 1,
                category: 2,
                question: 'Quantos voos você fez no ano passado com menos de 3 horas?',
                description: 'Digite o número de voos.',
                label: 'voos'
            },
            {
                id:7,
                type: 1,
                category: 2,
                question: 'Quantos voos você fez no ano passado com mais de 3 horas?',
                description: 'Digite o número de voos.',
                label: 'voos'
            },
        
            {
                id:1,
                type: 2,
                category: 1,
                question: 'Quantos funcionários sua empresa tem?',
                description: 'Antes de começarmos, queremos saber com quantas pessoas você trabalha. Isso nos ajudará a conhecer sua pegada de CO2, com os dados que você tem em mãos.',
                label: 'Pessoas'
            },
            {
                id:8,
                type: 2,
                category: 1,
                question: 'Qual o consumo de gás da sua empresa por mês?',
                description: 'Digite o valor em pesos colombianos (COP)',
                label: 'COP'
            },
            {
                id:9,
                type: 2,
                category: 1,
                question: 'Qual é o consumo de energia da sua empresa por mês?',
                description: 'Digite o valor em pesos colombianos (COP)',
                label: ' COP'
            },
            {
                id:10,
                type: 2,
                category: 2,
                question: 'Quantos voos seus funcionários fizeram no ano passado com menos de 3 horas?',
                description: 'Digite o número de voos.',
                label: 'voos'
            },
            {
                id:11,
                type: 2,
                category: 2,
                question: 'Quantos voos seus funcionários fizeram no ano passado com mais de 3 horas?',
                description: 'Digite o número de voos.',
                label: 'voos'
            },
            {
                id:12,
                type: 2,
                category: 3,
                question: 'Quantos galões desses combustíveis sua empresa usa por mês?',
                description: 'Digite o número de galões para cada combustível.',
                label: 'galões'
            },
            {
                id:13,
                type: 2,
                category: 3,
                question: 'Que tipo de refrigerante sua empresa usa? Indica seus valores estimados por mês',
                description: 'Selecione os tipos de refrigerantes que sua empresa usa',
                label: ''
            }
        ]
    }
}
