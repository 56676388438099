export const infoCombustible = {
    es: {
        Combustible: [
            {   
                id: 1,
                value: 'Gasolina',
                multiplicador: 8.812
            },
            { 
                id: 2,
                value: 'Diesel (B10)',
                multiplicador: 10.21
            },
            { 
                id: 3,
                value: 'Biodiesel',
                multiplicador: 0.0069
            },
            { 
                id: 4,
                value: 'Gas Natural',
                multiplicador: 7.075
            },
            { 
                id: 5,
                value: 'GLP',
                multiplicador: 0.5022
            },
        ]
    },
    en: {
        Combustible: [
            {   
                id: 1,
                value: 'Gasoline',
                multiplicador: 8.812
            },
            { 
                id: 2,
                value: 'Diesel (B10)',
                multiplicador: 10.21
            },
            { 
                id: 3,
                value: 'Biodiesel',
                multiplicador: 0.0069
            },
            { 
                id: 4,
                value: 'Natural gas',
                multiplicador: 7.075
            },
            { 
                id: 5,
                value: 'GLP',
                multiplicador: 0.5022
            },
        ]
    },
    pt: {
        Combustible: [
            {   
                id: 1,
                value: 'Gasolina',
                multiplicador: 8.812
            },
            { 
                id: 2,
                value: 'Diesel (B10)',
                multiplicador: 10.21
            },
            { 
                id: 3,
                value: 'Biodiesel',
                multiplicador: 0.0069
            },
            { 
                id: 4,
                value: 'Gas Natural',
                multiplicador: 7.075
            },
            { 
                id: 5,
                value: 'GLP',
                multiplicador: 0.5022
            },
        ]
    }
}

export const infoRefrigerant = {
    es:{
        Refrigerant: [
            {
                id:1,
                label: 'Dioxido de carbono',
                value: 'Dioxido de carbono',
                multiplicador: 1
            },
            {
                id:2,
                label: 'Metano',
                value: 'Metano',
                multiplicador: 28
            },
            {
                id:3,
                label: 'Oxido nitroso',
                value: 'Oxido nitroso',
                multiplicador: 265
            },
            {
                id:4,
                label: 'Trifluoruro de nitrógeno',
                value: 'Trifluoruro de nitrógeno',
                multiplicador: 16100
            },
            {
                id:5,
                label: 'hexafluoruro de azufre',
                value: 'hexafluoruro de azufre',
                multiplicador: 23500
            },
            {
                id:6,
                label: 'HFC-23 (R-23)',
                value: 'HFC-23',
                multiplicador: 12400
            },
            {
                id:7,
                label: 'HFC-32 (R-32)',
                value: 'HFC-32',
                multiplicador: 677
            },
            {
                id:8,
                label: 'HFC-41 (R-41)',
                value: 'HFC-41',
                multiplicador: 116
            },
            {
                id:9,
                label: 'HFC-125 (R-125)',
                value: 'HFC-125',
                multiplicador: 3150
            },
            {
                id:10,
                label: 'HFC-134 (R-134)',
                value: 'HFC-134',
                multiplicador: 1120
            },
            {
                id:11,
                label: 'HFC-134a (R-134a)',
                value: 'HFC-134a',
                multiplicador: 1300
            },
            {
                id:12,
                label: 'HFC-143 (R-143)',
                value: 'HFC-143',
                multiplicador: 328
            },
            {
                id:13,
                label: 'HFC-143a (R-143a)',
                value: 'HFC-143a',
                multiplicador: 4800
            },
            {
                id:14,
                label: 'HFC-152 (R-152)',
                value: 'HFC-152',
                multiplicador: 16
            },
            {
                id:15,
                label: 'HFC-152a (R-152a)',
                value: 'HFC-152a',
                multiplicador: 138
            },
            {
                id:16,
                label: 'HFC-161 (R-161)',
                value: 'HFC-161',
                multiplicador: 4
            },
            {
                id:17,
                label: 'HFC-227ea (R-227ea)',
                value: 'HFC-227ea',
                multiplicador: 3350
            },
            {
                id:18,
                label: 'HFC-236cb (R-236cb)',
                value: 'HFC-236cb',
                multiplicador: 1120
            },
            {
                id:19,
                label: 'HFC-236ea (R-236ea)',
                value: 'HFC-236ea',
                multiplicador: 1330
            },
            {
                id:20,
                label: 'HFC-236fa (R-236fa)',
                value: 'HFC-236fa',
                multiplicador: 8060
            },
            {
                id:21,
                label: 'HFC-245ca (R-245ca)',
                value: 'HFC-245ca',
                multiplicador: 716
            },
            {
                id:22,
                label: 'FC-245fa (R-245fa)',
                value: 'HFC-245fa',
                multiplicador: 858
            },
            {
                id:23,
                label: 'HFC-365mfc',
                value: 'HFC-365mfc',
                multiplicador: 804
            },
            {
                id:24,
                label: 'HFC-43-10mee (R-4310)',
                value: 'HFC-43-10mee',
                multiplicador: 1650
            },
            {
                id:25,
                label: 'PFC-14 (Perfluoromethane)',
                value: 'PFC-14',
                multiplicador: 6630
            },
            {
                id:26,
                label: 'PFC-116 (Perfluoroethane)',
                value: 'PFC-116',
                multiplicador: 11100
            },
            {
                id:27,
                label: 'PFC-218 (Perfluoropropane)',
                value: 'PFC-218',
                multiplicador: 8900
            },
            {
                id:28,
                label: 'PFC-318 (Perfluorocyclobutane)',
                value: 'PFC-318',
                multiplicador: 9540
            },
            {
                id:29,
                label: 'PFC-3-1-10 (Perfluorobutane)',
                value: 'PFC-3-1-10',
                multiplicador: 9200
            },
            {
                id:30,
                label: 'PFC-5-1-14 (Perfluorohexane)',
                value: 'PFC-5-1-14',
                multiplicador: 7910
            },
            {
                id:31,
                label: 'PFC-9-1-18 (Perfluorodecalin)',
                value: 'PFC-9-1-18',
                multiplicador: 7190
            },
        ]
    },
    en: {
        Refrigerant: [
            {
                id:1,
                label: 'Carbon dioxide',
                value: 'Carbon dioxide',
                multiplicador: 1
            },
            {
                id:2,
                label: 'Methane',
                value: 'Methane',
                multiplicador: 28
            },
            {
                id:3,
                label: 'Nitrous oxide',
                value: 'Nitrous oxide',
                multiplicador: 265
            },
            {
                id:4,
                label: 'Nitrogen trifluoride',
                value: 'Nitrogen trifluoride',
                multiplicador: 16100
            },
            {
                id:5,
                label: 'sulfur hexafluoride',
                value: 'sulfur hexafluoride',
                multiplicador: 23500
            },
            {
                id:6,
                label: 'HFC-23 (R-23)',
                value: 'HFC-23',
                multiplicador: 12400
            },
            {
                id:7,
                label: 'HFC-32 (R-32)',
                value: 'HFC-32',
                multiplicador: 677
            },
            {
                id:8,
                label: 'HFC-41 (R-41)',
                value: 'HFC-41',
                multiplicador: 116
            },
            {
                id:9,
                label: 'HFC-125 (R-125)',
                value: 'HFC-125',
                multiplicador: 3150
            },
            {
                id:10,
                label: 'HFC-134 (R-134)',
                value: 'HFC-134',
                multiplicador: 1120
            },
            {
                id:11,
                label: 'HFC-134a (R-134a)',
                value: 'HFC-134a',
                multiplicador: 1300
            },
            {
                id:12,
                label: 'HFC-143 (R-143)',
                value: 'HFC-143',
                multiplicador: 328
            },
            {
                id:13,
                label: 'HFC-143a (R-143a)',
                value: 'HFC-143a',
                multiplicador: 4800
            },
            {
                id:14,
                label: 'HFC-152 (R-152)',
                value: 'HFC-152',
                multiplicador: 16
            },
            {
                id:15,
                label: 'HFC-152a (R-152a)',
                value: 'HFC-152a',
                multiplicador: 138
            },
            {
                id:16,
                label: 'HFC-161 (R-161)',
                value: 'HFC-161',
                multiplicador: 4
            },
            {
                id:17,
                label: 'HFC-227ea (R-227ea)',
                value: 'HFC-227ea',
                multiplicador: 3350
            },
            {
                id:18,
                label: 'HFC-236cb (R-236cb)',
                value: 'HFC-236cb',
                multiplicador: 1120
            },
            {
                id:19,
                label: 'HFC-236ea (R-236ea)',
                value: 'HFC-236ea',
                multiplicador: 1330
            },
            {
                id:20,
                label: 'HFC-236fa (R-236fa)',
                value: 'HFC-236fa',
                multiplicador: 8060
            },
            {
                id:21,
                label: 'HFC-245ca (R-245ca)',
                value: 'HFC-245ca',
                multiplicador: 716
            },
            {
                id:22,
                label: 'FC-245fa (R-245fa)',
                value: 'HFC-245fa',
                multiplicador: 858
            },
            {
                id:23,
                label: 'HFC-365mfc',
                value: 'HFC-365mfc',
                multiplicador: 804
            },
            {
                id:24,
                label: 'HFC-43-10mee (R-4310)',
                value: 'HFC-43-10mee',
                multiplicador: 1650
            },
            {
                id:25,
                label: 'PFC-14 (Perfluoromethane)',
                value: 'PFC-14',
                multiplicador: 6630
            },
            {
                id:26,
                label: 'PFC-116 (Perfluoroethane)',
                value: 'PFC-116',
                multiplicador: 11100
            },
            {
                id:27,
                label: 'PFC-218 (Perfluoropropane)',
                value: 'PFC-218',
                multiplicador: 8900
            },
            {
                id:28,
                label: 'PFC-318 (Perfluorocyclobutane)',
                value: 'PFC-318',
                multiplicador: 9540
            },
            {
                id:29,
                label: 'PFC-3-1-10 (Perfluorobutane)',
                value: 'PFC-3-1-10',
                multiplicador: 9200
            },
            {
                id:30,
                label: 'PFC-5-1-14 (Perfluorohexane)',
                value: 'PFC-5-1-14',
                multiplicador: 7910
            },
            {
                id:31,
                label: 'PFC-9-1-18 (Perfluorodecalin)',
                value: 'PFC-9-1-18',
                multiplicador: 7190
            },
        ]
    },
    pt: {
        Refrigerant: [
            {
                id:1,
                label: 'Dioxido de carbono',
                value: 'Dioxido de carbono',
                multiplicador: 1
            },
            {
                id:2,
                label: 'Metano',
                value: 'Metano',
                multiplicador: 28
            },
            {
                id:3,
                label: 'Oxido nitroso',
                value: 'Oxido nitroso',
                multiplicador: 265
            },
            {
                id:4,
                label: 'Trifluoreto de nitrogênio',
                value: 'Trifluoreto de nitrogênio',
                multiplicador: 16100
            },
            {
                id:5,
                label: 'hexafluoreto de enxofre',
                value: 'hexafluoreto de enxofre',
                multiplicador: 23500
            },
            {
                id:6,
                label: 'HFC-23 (R-23)',
                value: 'HFC-23',
                multiplicador: 12400
            },
            {
                id:7,
                label: 'HFC-32 (R-32)',
                value: 'HFC-32',
                multiplicador: 677
            },
            {
                id:8,
                label: 'HFC-41 (R-41)',
                value: 'HFC-41',
                multiplicador: 116
            },
            {
                id:9,
                label: 'HFC-125 (R-125)',
                value: 'HFC-125',
                multiplicador: 3150
            },
            {
                id:10,
                label: 'HFC-134 (R-134)',
                value: 'HFC-134',
                multiplicador: 1120
            },
            {
                id:11,
                label: 'HFC-134a (R-134a)',
                value: 'HFC-134a',
                multiplicador: 1300
            },
            {
                id:12,
                label: 'HFC-143 (R-143)',
                value: 'HFC-143',
                multiplicador: 328
            },
            {
                id:13,
                label: 'HFC-143a (R-143a)',
                value: 'HFC-143a',
                multiplicador: 4800
            },
            {
                id:14,
                label: 'HFC-152 (R-152)',
                value: 'HFC-152',
                multiplicador: 16
            },
            {
                id:15,
                label: 'HFC-152a (R-152a)',
                value: 'HFC-152a',
                multiplicador: 138
            },
            {
                id:16,
                label: 'HFC-161 (R-161)',
                value: 'HFC-161',
                multiplicador: 4
            },
            {
                id:17,
                label: 'HFC-227ea (R-227ea)',
                value: 'HFC-227ea',
                multiplicador: 3350
            },
            {
                id:18,
                label: 'HFC-236cb (R-236cb)',
                value: 'HFC-236cb',
                multiplicador: 1120
            },
            {
                id:19,
                label: 'HFC-236ea (R-236ea)',
                value: 'HFC-236ea',
                multiplicador: 1330
            },
            {
                id:20,
                label: 'HFC-236fa (R-236fa)',
                value: 'HFC-236fa',
                multiplicador: 8060
            },
            {
                id:21,
                label: 'HFC-245ca (R-245ca)',
                value: 'HFC-245ca',
                multiplicador: 716
            },
            {
                id:22,
                label: 'FC-245fa (R-245fa)',
                value: 'HFC-245fa',
                multiplicador: 858
            },
            {
                id:23,
                label: 'HFC-365mfc',
                value: 'HFC-365mfc',
                multiplicador: 804
            },
            {
                id:24,
                label: 'HFC-43-10mee (R-4310)',
                value: 'HFC-43-10mee',
                multiplicador: 1650
            },
            {
                id:25,
                label: 'PFC-14 (Perfluoromethane)',
                value: 'PFC-14',
                multiplicador: 6630
            },
            {
                id:26,
                label: 'PFC-116 (Perfluoroethane)',
                value: 'PFC-116',
                multiplicador: 11100
            },
            {
                id:27,
                label: 'PFC-218 (Perfluoropropane)',
                value: 'PFC-218',
                multiplicador: 8900
            },
            {
                id:28,
                label: 'PFC-318 (Perfluorocyclobutane)',
                value: 'PFC-318',
                multiplicador: 9540
            },
            {
                id:29,
                label: 'PFC-3-1-10 (Perfluorobutane)',
                value: 'PFC-3-1-10',
                multiplicador: 9200
            },
            {
                id:30,
                label: 'PFC-5-1-14 (Perfluorohexane)',
                value: 'PFC-5-1-14',
                multiplicador: 7910
            },
            {
                id:31,
                label: 'PFC-9-1-18 (Perfluorodecalin)',
                value: 'PFC-9-1-18',
                multiplicador: 7190
            },
        ]
    }
} 

export const infoTransports = {
    es: {
        Transports: [
            {   
                id: 1,
                label: 'Carro',
                value: 'Carro',
                multiplicador: 0.3464
            },
            { 
                id: 2,
                label: 'Moto',
                value: 'Moto',
                multiplicador: 0
            },
            { 
                id: 3,
                label: 'Bicicleta',
                value: 'Bicicleta',
                multiplicador: 0
            },
            { 
                id: 4,
                label: 'Transporte público',
                value: 'Transporte público',
                multiplicador: 0
            },
        ]
    },
    en: {
        Transports: [
            {   
                id: 1,
                label: 'car',
                value: 'car',
                multiplicador: 0.3464
            },
            { 
                id: 2,
                label: 'Motorcycle',
                value: 'Motorcycle',
                multiplicador: 0
            },
            { 
                id: 3,
                label: 'Bicycle',
                value: 'Bicycle',
                multiplicador: 0
            },
            { 
                id: 4,
                label: 'Public transport',
                value: 'Public transport',
                multiplicador: 0
            },
        ]
    },
    pt:{
        Transports: [
            {   
                id: 1,
                label: 'Carro',
                value: 'Carro',
                multiplicador: 0.3464
            },
            { 
                id: 2,
                label: 'Motocicleta',
                value: 'Motocicleta',
                multiplicador: 0
            },
            { 
                id: 3,
                label: 'Bicicleta',
                value: 'Bicicleta',
                multiplicador: 0
            },
            { 
                id: 4,
                label: 'Transporte público',
                value: 'Transporte público',
                multiplicador: 0
            },
        ]
    },
}