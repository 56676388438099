import { useContext } from "react"
import { LanguajeContext } from "../context/LanguajeContext"

export const CardWelcome = ({typeUsu, setTypeUsu}) => {

  const { data: {infoCardWelcome} } = useContext( LanguajeContext );
  
  return (
    <div className="CardWelcome">
        <div className="text-wellcome">
          <h2>{infoCardWelcome.title}</h2>

        </div>
        <div className="CarWelcome-buttons">
            <p>{infoCardWelcome.Paragraph}</p>
            <button onClick={() => setTypeUsu(1) } className={typeUsu === 1 ? 'active': ''}> {infoCardWelcome.personButton}</button>
            <button onClick={() => setTypeUsu(2) } className={typeUsu === 2 ? 'active': ''}> {infoCardWelcome.companyButton}</button>
        </div>
    </div>
  )
}

export default CardWelcome
