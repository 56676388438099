export const infoCardWelcome = {
    es: {
        title: 'Calcula tus emisiones y reduce tu huella de carbono',
        Paragraph: 'Selecciona un tipo de usuario:',
        personButton: 'Persona',
        companyButton: 'Empresa',
    },
    en: {
        title: 'Calculate your emissions and reduce your carbon footprint',
        Paragraph: 'Select a type of user:',
        personButton: 'Person',
        companyButton: 'Company',
    },
    pt: {
        title: 'Calcule sua pegada de carbono',
        Paragraph: 'Selecione um tipo de usuário:',
        personButton: 'Pessoa',
        companyButton: 'Empresa',
    },
}