
import { useEffect, useState } from "react"
import { TiChevronRight, TiChevronLeft } from 'react-icons/ti';
import useData from "../hooks/useData";
import Question from './utils/Question'

// importacion de los array de preguntas

import { useContext } from "react";
import { LanguajeContext } from "../context/LanguajeContext";


const FooterQuestion = ({setPosActual, posActual, length, answer, answers, data, setAnswers, setTerminate, setMesaggeError, setTypeUsu}) => {

    const { data: { infoSectionQuestion: { FooterQuestion } } } = useContext( LanguajeContext );

    const saveAnswers = (answers, id, answer, newPosition) => {

        if(id === 1 && answer <= 0){
            setMesaggeError(FooterQuestion.firstMesaggeError)
            setTimeout(() => { return setMesaggeError('') }, 3000)  //limpiar el mensaje de error
            return false
        }

        if(id === 5 && !answer.id && newPosition === 'next'){
            setMesaggeError(FooterQuestion.secondMesaggeError)
            setTimeout(() => { return setMesaggeError('') }, 3000)  //limpiar el mensaje de error
            return false
        }

        if(answers.find( element => element.id === id)) {

            let elementIndex = answers.findIndex((element => element.id === id))
            answers[elementIndex].answer = answer

        }else{
            setAnswers([...answers, {id, answer}])
        }

        newPosition === 'next' ? setPosActual(posActual+1) : setPosActual(posActual-1)
        setMesaggeError('')
    }

    return (
        <div className={`footer-questions`}>
            {   
                posActual !== 0 ? (
                    <button className="footer-button--prev" onClick={() => {saveAnswers(answers, data.id , answer, 'prev')}}>
                        <TiChevronLeft/> {FooterQuestion.backButton}
                    </button>
                ):(
                    <button className="footer-button--prev" onClick={() => {
                        setTypeUsu(0)
                    }}>
                        <TiChevronLeft/> {FooterQuestion.backButton} 
                    </button>
                )
            }
            {
                length === posActual ? (
                    <button className="footer-button--next" onClick={() => {
                        saveAnswers(answers, data.id , answer)
                        setTerminate(true)
                    }}> 
                        {FooterQuestion.finishButton}
                        <TiChevronRight/>
                    </button>
                ):( <button className="footer-button--next" onClick={() => {
                        saveAnswers(answers, data.id , answer, 'next')
                    }}> 
                        {FooterQuestion.nextButton}
                        <TiChevronRight/>
                    </button>)
            }
            
        </div>
    )
}

const SectionQuestion = () => {
    const [posActual, setPosActual] = useState(0)
    const [data, setData] = useState({})
    const [lengthArr, setLengthArr] = useState({})
    const [answer, setAnswer] = useState(0);

    const { data: { infoDescriptionQuestions } } = useContext( LanguajeContext );
    const {
        typeUsu, 
        category, 
        setCategory, 
        answers, 
        setAnswers, 
        setTerminate,
        setMesaggeError, 
        setTypeUsu,
    } = useData()

    const filterQuestions = (posActual, typeUsu) => {
        let filterQuestions = infoDescriptionQuestions.Questions.filter( question =>(question.type === typeUsu && question.category !== 0))
        let dataQuestionFilter = filterQuestions[posActual]

        setData(dataQuestionFilter)
        setLengthArr(filterQuestions.length-1)
    }

    useEffect ( () => {
        if (category !== 0)  {
            filterQuestions(posActual, typeUsu)
            setCategory(data.category)
        }
    }, [posActual, typeUsu, data])


    return (
        <>
            <div className='section-questions'>
                <Question 
                    number={posActual} 
                    Question={data}
                    answer = {answer}
                    setAnswer = {setAnswer}
                    answers={answers}
                    setAnswers={setAnswers}
                />
            </div>
            <FooterQuestion 
                setPosActual={setPosActual} 
                posActual={posActual} 
                length={lengthArr}
                answer = {answer}
                answers = {answers}
                setAnswers={setAnswers}
                setTerminate={setTerminate}
                setMesaggeError={setMesaggeError}
                setTypeUsu ={setTypeUsu}
                data={data}
            />
        </>
  )
}

export default SectionQuestion
