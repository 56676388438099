export const infoSectionCategories = {
    es: {
        paragraph: 'preguntas',
        person: {
            firstTitle: 'Hogar',
            secondTitle: 'Transporte',
        },
        company: {
            firstTitle: 'Empresa',
            secondTitle: 'Transporte',
            thirdTitle: 'Combustibles'
        },
    },
    en: {
        paragraph: 'questions',
        person: {
            firstTitle: 'Home',
            secondTitle: 'Transport',
        },
        company: {
            firstTitle: 'Company',
            secondTitle: 'Transport',
            thirdTitle: 'Fuels'
        },
    },
    pt: {
        paragraph: 'questões',
        person: {
            firstTitle: 'Lar',
            secondTitle: 'Transporte',
        },
        company: {
            firstTitle: 'Empresa',
            secondTitle: 'Transporte',
            thirdTitle: 'Combustíveis'
        },
    }
}