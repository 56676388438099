import { useEffect, useState } from "react"
import { isArrEmpty} from '../../functions/Functions'
import { TiMinus } from 'react-icons/ti';
import Select from 'react-select'
import { useContext } from "react";
import { LanguajeContext } from "../../context/LanguajeContext";

const removeOptionSelected = (id, selectedOption, setSelectedOption) => {
    if(selectedOption.find( element => element.id === id)) {
        setSelectedOption(selectedOption.filter((element => element.id !== id)))
    }
}

const InputQuestion = ({idQuestion, number, answer, setAnswer, label, inputs, setSelectedOption}) => {
    const [selectedOpTransport, setSelectedOpTransport] = useState([]);
    const [answerTypeObject, setAnswerTypeObject] = useState({value: 0})

    const { data: { infoTransports } } = useContext( LanguajeContext );

    useEffect(() =>{
        saveUniqueAnswer(answerTypeObject.value)
    }, [selectedOpTransport])


    const saveMultiAnswer = (id, value) => {

        if(answer.find( element => element.id === id)) {
            let elementIndex = answer.findIndex((element => element.id === id))
            answer.splice(elementIndex, 1)
        }
         
        setAnswer([...answer, {id, value}])
    }

    const saveUniqueAnswer = (value) => {
        setAnswerTypeObject({id: selectedOpTransport.id, value})

        if (idQuestion === 5) setAnswer({id: selectedOpTransport.id, value})
    }
    

    if(idQuestion === 12 || idQuestion === 13){
        return (
        inputs.map( (element, index) => {
            let valueMulti = {value: 0}
            if (Array.isArray(answer) && !isArrEmpty(answer) && answer.find( item => item.id === element.id))
                valueMulti = answer.find( item => item.id === element.id)
            
            return  <li className='InputList' key={index+1}>    
                        { idQuestion === 13 ? <button className="input-delete" onClick={() => {removeOptionSelected(element.id, inputs, setSelectedOption)}}> <TiMinus/> </button>: null }
                        <label>
                            <strong>{element.value}</strong>
                            <input 
                                type='number' 
                                key={number+1}
                                value={valueMulti.value}
                                onChange={ev => saveMultiAnswer(element.id, ev.target.value) }
                            />
                        </label>
                    </li>
        }))
        
    }else if(idQuestion === 5) {
        return  <li>
                    <Select
                        className="select"
                        placeholder = "Selecciona tu medio de transporte"
                        defaultValue={selectedOpTransport}
                        onChange={e  => {setSelectedOpTransport(e)} }
                        options={infoTransports.Transports}
                    />
                    <label>
                        <strong>{label}</strong>
                        <input 
                            type='number' 
                            key={number+1}
                            value={answerTypeObject.value}
                            onChange={ev =>  saveUniqueAnswer(ev.target.value)  }
                        />
                    </label>
                </li>

    }else{
        return  <li>
                    <label>
                        <strong>{label}</strong>
                        <input 
                            type='number' 
                            key={number+1}
                            value={answer}
                            onChange={ev =>  setAnswer(ev.target.value) }
                        />
                    </label>
                </li>
    }

}

export default InputQuestion