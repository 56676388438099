import SectionCategories from "./SectionCategories"
import SectionQuestion from "./SectionQuestion"
import CardWelcome from "./CardWelcome"; 
import CardTerminate from "./CardTerminate"; 

import useData from "../hooks/useData";


const BodyCard = () => {
  const {
      typeUsu, 
      setTypeUsu, 
      terminate, 
    } = useData()

  return (
    <div className='body-main'>
        {
          terminate ? 
            <CardTerminate/>
            :typeUsu === 0 ?
                <CardWelcome
                  setTypeUsu={setTypeUsu}
                  typeUsu={typeUsu}
                />
                : <>            
                    <SectionCategories/> 
                    <SectionQuestion/>
                  </>
        }
    </div>
  )
}

export default BodyCard