import { createContext, useState } from 'react';
 
export const AppContext = createContext({});

export const AppProvider = (props) =>{
    const { children } = props
    const [typeUsu, setTypeUsu] = useState(0)
    const [category, setCategory] = useState(1)
    const [answers, setAnswers] = useState([])
    
    const [terminate, setTerminate] = useState(false)
    const [messageError, setMesaggeError] = useState('')

    const valueContext = {
        typeUsu,
        setTypeUsu,
        category,
        setCategory,
        answers,
        setAnswers,
        terminate,
        setTerminate,
        messageError,
        setMesaggeError
    }

    return (
        <AppContext.Provider value={valueContext}>
            {children}
        </AppContext.Provider>
    )
}

 