import BodyCard from "./BodyCard"

const CardMain = () => {
  return (
    <div className='card-main'>
        <BodyCard/>
    </div>
  )
}

export default CardMain