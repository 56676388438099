import { infoCardWelcome, 
    infoCartTerminate, 
    infoCombustible, 
    infoDescriptionQuestions, 
    infoQuestions, 
    infoRefrigerant, 
    infoSectionCategories, 
    infoSectionQuestion, 
    infoTransports} from "./translations";

export const infoAllData = {
    es: {
        infoCardWelcome: infoCardWelcome.es,
        infoCartTerminate: infoCartTerminate.es,
        infoSectionCategories: infoSectionCategories.es,
        infoSectionQuestion: infoSectionQuestion.es,
        infoDescriptionQuestions: infoDescriptionQuestions.es,
        infoQuestions: infoQuestions.es,
        infoCombustible: infoCombustible.es,
        infoRefrigerant: infoRefrigerant.es,
        infoTransports: infoTransports.es,
    },
    en:{
        infoCardWelcome: infoCardWelcome.en,
        infoCartTerminate: infoCartTerminate.en,
        infoSectionCategories: infoSectionCategories.en,
        infoSectionQuestion: infoSectionQuestion.en,
        infoDescriptionQuestions: infoDescriptionQuestions.en,
        infoQuestions: infoQuestions.en,
        infoCombustible: infoCombustible.en,
        infoRefrigerant: infoRefrigerant.en,
        infoTransports: infoTransports.en,
    },
    pt:{
        infoCardWelcome: infoCardWelcome.pt,
        infoCartTerminate: infoCartTerminate.pt,
        infoSectionCategories: infoSectionCategories.pt,
        infoSectionQuestion: infoSectionQuestion.pt,
        infoDescriptionQuestions: infoDescriptionQuestions.pt,
        infoQuestions: infoQuestions.pt,
        infoCombustible: infoCombustible.pt,
        infoRefrigerant: infoRefrigerant.pt,
        infoTransports: infoTransports.pt,
    },
}