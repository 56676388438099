import { useContext, useEffect, useState } from "react"
import {isObjEmpty, isArrEmpty} from '../../functions/Functions'
import InputQuestion from './InputQuestion'
import Select from 'react-select'
import useData from "../../hooks/useData"
import { LanguajeContext } from "../../context/LanguajeContext"

const addOptionSelected = (option, selectedOption, setSelectedOption, setMesaggeError, messageError) => {

    if(selectedOption.length > 7){
        setMesaggeError(messageError)
        return false
    }

    setSelectedOption([...selectedOption, option]) 
}

const Question = ({number, Question, answer, setAnswer, answers}) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const {setMesaggeError, messageError} = useData()

    const { data: { infoQuestions: { infoQuestion, infoAddOptionSelected } } } = useContext( LanguajeContext );
    const { data: { infoCombustible, infoRefrigerant } } = useContext( LanguajeContext );

    useEffect( () => {
        if(!isObjEmpty(Question) && !isArrEmpty(answers)){
            let filterAnswer = answers.filter ( answer => answer.id === Question.id)
            if (!isArrEmpty(filterAnswer))
                setAnswer(filterAnswer[0].answer); 
            else
                Question.id === 12 || Question.id === 13 ? setAnswer([]) : setAnswer(0)
        }
        
    }, [Question])

    return (
        <div className='question'>
            <h3>{infoQuestion.subtitle} {number+1}</h3>
            <h1>{Question.question}</h1>
            <p>{Question.description}</p>
            <p className="errorInput">{messageError}</p>
            {
                Question.id === 13 ? 
                
                    <>
                        <div className="content-select">
                            <Select
                                className="select"
                                placeholder = {infoQuestion.select}
                                defaultValue={selectedOption}
                                onChange={e  => addOptionSelected(e , selectedOption, setSelectedOption, setMesaggeError, infoAddOptionSelected.messageError ) }
                                options={infoRefrigerant.Refrigerant}
                            />
                        </div>

                        <ul>
                            <InputQuestion
                                idQuestion={Question.id}
                                answer={answer}
                                setAnswer={setAnswer}
                                number={number}
                                inputs={selectedOption}
                                setSelectedOption = {setSelectedOption}
                            />
                        </ul>
                    </>
                    :
                    <>
                        <ul>
                            <InputQuestion 
                                idQuestion={Question.id}
                                answer={answer}
                                setAnswer={setAnswer}
                                number={number}
                                label={Question.label}
                                inputs={infoCombustible.Combustible}
                            />
                        </ul>
                    </>
            }
            
        </div>
    )
}

export default Question