
import { useContext } from "react";
import Questions from "../data/translations/infoDescriptionQuestions";
import useData from "../hooks/useData";
import { LanguajeContext } from "../context/LanguajeContext";



const Categorie = ({title, bg, selected, category, typeUsu, description}) => {
    const {answers} = useData()
    const { data: { infoSectionCategories: {paragraph} } } = useContext( LanguajeContext );

    const { data: { infoDescriptionQuestions } } = useContext( LanguajeContext );

    let cuestionsForCategory = infoDescriptionQuestions.Questions.filter( element => element.category === category && element.type === typeUsu)

    return (
        <div className={`category-question ${bg} ${selected}`}>
            <h2>{title}</h2>
            {/* <p>
                {description}
            </p> */}
            <div className='footer-category'>
                <ul className='check-questions'>
                    {
                        cuestionsForCategory.map( (element, index) => {
                            let findAnswer = answers.find( ans => ans.id === element.id )

                            return  <li key={index+1} id={element.id}>
                                        {
                                            findAnswer ? (<span></span>) : null
                                        }
                                    </li>
                        })
                    }
                    <p> {cuestionsForCategory.length} {paragraph}</p>
                </ul>
            </div>
        </div>
    )
}

const SectionCategories = () => {
    const {typeUsu, category} = useData()
    const { data: { infoSectionCategories } } = useContext( LanguajeContext );

    return (
            typeUsu === 1 ? 
            <div className='section-categories'>
                <Categorie 
                    title={infoSectionCategories.person.firstTitle} 
                    bg='cat1' 
                    selected={category === 1 ? 'selected' : ''}
                    category={1}
                    typeUsu={typeUsu}
                    description='¿Cuáles son los hábitos de consumo energético en tu hogar?'
                />
                <Categorie 
                    title={infoSectionCategories.person.secondTitle}
                    bg='cat2' 
                    selected={category === 2 ? 'selected' : ''}
                    category={2}
                    typeUsu={typeUsu}
                    description='¿Cómo te mueves por la ciudad? ¿Tienes tu propio transporte o utilizas el transporte público?'
                />
            </div>
            :
            <div className='section-categories'>
                <Categorie 
                    title={infoSectionCategories.company.firstTitle}
                    bg='cat1' 
                    selected={category === 1 ? 'selected' : ''}
                    category={1}
                    typeUsu={typeUsu}
                    description='¿Cuáles son los hábitos de consumo energético en tu Empresa?'
                />
                <Categorie 
                    title={infoSectionCategories.company.secondTitle} 
                    bg='cat2' 
                    selected={category === 2 ? 'selected' : ''}
                    category={2}
                    typeUsu={typeUsu}
                    description='¿Cómo te mueves por la ciudad? ¿Tienes tu propio transporte o utilizas el transporte público?'
                />
                <Categorie 
                    title={infoSectionCategories.company.thirdTitle} 
                    bg='cat3' 
                    selected={category === 3 ? 'selected' : ''}
                    category={3}
                    typeUsu={typeUsu}
                    description='¿Cómo te mueves por la ciudad? ¿Tienes tu propio transporte o utilizas el transporte público?'
                />
            </div>
        
    )
}

export default SectionCategories

