export const infoSectionQuestion = {
    es:{
        FooterQuestion: {
            firstMesaggeError: 'Para continuar el número de personas debe ser diferente de cero',
            secondMesaggeError: 'Para continuar debes seleccionar un medio de transporte',
            backButton: 'Anterior',
            finishButton: 'Terminar',
            nextButton: 'Siguiente'
        },
    },
    en:{
        FooterQuestion: {
            firstMesaggeError: 'To continue the number of people must be different from zero',
            secondMesaggeError: 'To continue you must select a means of transport',
            backButton: 'Back',
            finishButton: 'Finish',
            nextButton: 'Next'
        },
    },
    pt: {
        FooterQuestion: {
            firstMesaggeError: 'Para continuar o número de pessoas deve ser diferente de zero',
            secondMesaggeError: 'Para continuar você deve selecionar um meio de transporte',
            backButton: 'Voltar',
            finishButton: 'Terminar',
            nextButton: 'seguinte',
        },
    }
}