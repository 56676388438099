const FindFactorEmission = (id, array) => {
    let FactorEmssion = array.find( element => element.id === id)
    return FactorEmssion.multiplicador
}

const Logica = (answers, Combustible, Refrigerant, Transports)=>{
    
    let Total = 0
    let Persons = answers[0].answer

    answers.forEach(element => {
        switch (element.id) {
            case 8:
            case 2:
                // calculo: consumo / 5655 / numero de personas * 12 * 1.9801 (factor de emision) * 0.001
                Total += (element.answer / 5655 / Persons) * 12 * 1.9801 * 0.001
            break;
            case 3:
                // calculo: consumo / 677.3124 / 1000 / numero de personas * 12 * 0.166 (factor de emision)
                Total += (element.answer / 677.3124 / 1000) / Persons * 12 * 0.166
            break;
            case 4:
                // calculo: Frecuencia * 0.1 * 52 * 67.6 (factor de emision) * 0.001
                Total += (element.answer * 0.1 * 52 * 67.6 * 0.001)
            break;
            case 5:
                // calculo: 
                    // carro: Km * 52 * 0.3464 (factor de emision) * 0.001 
                    // moto: Km * 52 * 0.1928 (factor de emision) * 0.001
                Total += (element.answer.value * 52 * FindFactorEmission(element.answer.id, Transports)) * 0.001
            break;
            case 10:
            case 6:
                // calculo: Vuelos * 250 * 0.22701 (factor de emision) * 0.001 
                Total += (element.answer * 250 * 0.22701 * 0.001)
            break;
            case 11:
            case 7:
                // calculo: Vuelos * 4450 * 0.1948 (factor de emision) *0.001
                Total += (element.answer * 4450 * 0.1948 * 0.001)
            break;
            case 9:
                // calculo: consumo / 817.1098 / 1000 / numero de personas * 12 * 0.166 (factor de emision)
                Total += (element.answer / 817.1098 / 1000) * 12 * 0.166
            break;
            case 12:
                // [Gasolina(E10): 7.6181, Disel(B10): 10.2163, Biodiesel: 0.006278, Gas Natural: 7.075, GLP: 3.0512]
                element.answer.forEach(item => {
                    Total += (parseInt(item.value) * FindFactorEmission(item.id, Combustible) * 0.001 * 12)
                })
            break;
            case 13:
                element.answer.forEach(item => {
                    Total += (parseInt(item.value) * FindFactorEmission(item.id, Refrigerant) * 0.001)
                })
            break;

            default:
                Total += 0
        }
    });
    
    return Total
} 

export default Logica