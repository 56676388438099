export const infoQuestions = {
    es: {
        infoAddOptionSelected: {
            messageError: 'No puedes agregar más opciones a la lista',
        },
        infoQuestion: {
            subtitle: 'Pregunta',
            select: 'Selecciona varios refrigerantes',
        },
    },
    en: {
        infoAddOptionSelected: {
            messageError: 'You cannot add more options to the list',
        },
        infoQuestion: {
            subtitle: 'question',
            select: 'Select various refrigerants',
        },
    },
    pt: {
        infoAddOptionSelected: {
            messageError: 'Você não pode adicionar mais opções à lista',
        },
        infoQuestion: {
            subtitle: 'questão',
            select: 'Seleccione varios refrescos',
        },
    },
}