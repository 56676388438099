import React, { useEffect, useState } from 'react'
import { TiChevronRight, TiChevronLeft } from 'react-icons/ti';
import useData from '../hooks/useData';
import Logica from "../logica/Logica";
import logo from '../assets/logo.png'
import { useContext } from 'react';
import { LanguajeContext } from '../context/LanguajeContext';


const MessageCard = ({typeUsu, total}) => {
    
    const { data: { 
        infoCartTerminate: { 
            MessageCard: {
                person, 
                company, 
                firstButton, 
                secondButton 
            }
        }
    } } = useContext( LanguajeContext );

    const getStoreUrl = () => {
        const currentUrl = window.location.href;
        var aux = '';
        if (currentUrl === 'https://calculator.biofix.co/?lang=/en/') {
            aux = 'https://pagosbiofix.co/tienda/';
        } else if (currentUrl === 'https://calculator.biofix.co/?lang=/es/') {
            aux = 'https://pagosbiofix.co/es/tienda/';
        }

        return aux; // URL por defecto
    }

    return <>
            {
                typeUsu === 1 ? 
                <div className='message'>
                    <p>
                        {person.firstParagraph}
                    </p>
                    <p>
                        {person.secondParagraph1} <strong>{total.toFixed(2)}</strong> {person.secondParagraph2}
                    </p>
                </div>
                : <div className='message'>
                    <p>
                        {company.firstParagraph1}
                        <br/>
                        <br/>

                        {company.firstParagraph2}
                    </p>
                    <p>
                        {company.secondParagraph1} <a href='mailto:operaciones@biofix.com.co' target={'_blank'} rel="noreferrer">operaciones@biofix.com.co</a>
                    </p>
                </div>
            }
            

            <div className='footer-terminate'>
                            
                <button className="footer-terminate--prev" onClick={()=>{window.location.reload(false)}}>
                    <TiChevronLeft/>
                    {firstButton} 
                </button>
                {
                    typeUsu === 1 && <button className="footer-terminate--next" onClick={() => window.open(getStoreUrl(), '_blank')}>
                    {secondButton}
                    <TiChevronRight />
                </button>
                }
            </div>
        </>

}

const CardTerminate = () => {

    const [total, setTotal] = useState(0)
    const {answers, typeUsu} = useData()

    const { data: { 
        infoCombustible, 
        infoRefrigerant, 
        infoTransports, 
        infoCartTerminate: {
            CardTerminate: { 
                person, 
                company, 
                h3 
            } 
        } 
    } } = useContext( LanguajeContext );


    useEffect ( ()=> {
        setTotal(Logica(answers, infoCombustible.Combustible, infoRefrigerant.Refrigerant, infoTransports.Transports));
    }, [answers, infoCombustible.Combustible, infoRefrigerant.Refrigerant, infoTransports.Transports])
    
    return (
        <div className='cardTerminate'>
            <h1>{typeUsu === 1 ?  person.title : company.title }</h1>
            <img src={logo} alt='logo-empresa' className='logo-terminate'/>

            <div className='emissions-total'>
                <p>{typeUsu === 1 ? person.Paragraph : company.Paragraph }</p>
                <h3> {total.toFixed(2)} <span>tonCO<sub>2</sub>eq/{h3}</span></h3>
            </div>

            <MessageCard typeUsu={typeUsu} total={total}/>
            
        </div>
    )
}

export default CardTerminate
