import React, { createContext, useState } from "react";
import { infoAllData } from "../data/infoAllData";
import { useEffect } from "react";

export const LanguajeContext = createContext();



export const LanguajeProvider = ({ children }) => {
    
    const [data, setData] = useState(infoAllData['en']);
    useEffect(() => {
        const parameterUrl = window.location.href;
        // Aquí cambiamos el estado de la variable
        if(parameterUrl.includes('/es/')){
            setData(infoAllData['es']);
        }

        if(parameterUrl.includes('/pt/')){
            setData(infoAllData['pt']);
        }
        
      }, []);
      
    
    return (
        <LanguajeContext.Provider value={{data}}>
            { children }
        </LanguajeContext.Provider>
    )
}     